import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk/dist/src/WixOOISDKAdapter';
import { FailReasons } from '../constants';
import { WidgetConfig } from '../../../types/shared-types';
import { biDefaults } from '../bi/consts';
import {
  isServiceConnectedToPricingPlan,
  isServiceOfferedAsPricingPlan,
} from '@wix/bookings-uou-mappers';
import { ServiceType } from '@wix/bookings-uou-types';
import { getTrackingInfoForBookButtonClick } from '@wix/bookings-analytics-adapter';
import { isEcomSite } from '../../../api/siteIsEcom';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { navigateToBookingsFormPage } from '@wix/bookings-booking-navigation';
import { navigateToBookingsCalendarPage } from '@wix/bookings-catalog-calendar-viewer-navigation';
import {
  isDemoMode,
  isPricingPlanInstalled as isPricingPlanInstalledUtils,
  isTemplateMode,
  trackAnalytics,
} from '@wix/bookings-catalog-calendar-viewer-utils';

export const handleNavigation = async ({
  config,
  isPreview,
  wixSdkAdapter,
  onNavigationFailed,
  locationId,
  timezone,
  flowAPI,
  isAnywherePublicDataOverridesEnabled = false,
  isBookingsV1ShutdownEnabled,
}: {
  config: WidgetConfig;
  wixSdkAdapter: WixOOISDKAdapter;
  isPreview: boolean;
  onNavigationFailed: ({ failReasons }: { failReasons: FailReasons[] }) => void;
  locationId?: string;
  timezone?: string;
  flowAPI: ControllerFlowAPI;
  isAnywherePublicDataOverridesEnabled?: boolean;
  isBookingsV1ShutdownEnabled: boolean;
}) => {
  const isUseUtilsInsteadOfWixSDKEnabled = flowAPI.experiments.enabled(
    'specs.bookings.isUseUtilsInsteadOfWixSDKEnabled',
  );
  const featureEnabled = config.isFeatureEnabled;
  const siteIsEcom =
    !isBookingsV1ShutdownEnabled || (await isEcomSite(flowAPI));
  const isPricingPlanInstalled = isUseUtilsInsteadOfWixSDKEnabled
    ? await isPricingPlanInstalledUtils(flowAPI.controllerConfig.wixCodeApi)
    : await wixSdkAdapter.isPricingPlanInstalled();
  if (
    isPreview ||
    (isUseUtilsInsteadOfWixSDKEnabled
      ? isDemoMode({
          wixCodeApi: flowAPI.controllerConfig.wixCodeApi,
          appParams: flowAPI.controllerConfig.appParams,
        })
      : wixSdkAdapter.isDemoMode()) ||
    (isUseUtilsInsteadOfWixSDKEnabled
      ? isTemplateMode({
          wixCodeApi: flowAPI.controllerConfig.wixCodeApi,
          appParams: flowAPI.controllerConfig.appParams,
        })
      : wixSdkAdapter.isTemplateMode()) ||
    (featureEnabled &&
      siteIsEcom &&
      (await isPricingPlanNavigateEnabled(
        isPricingPlanInstalled,
        config.serviceDto,
      )))
  ) {
    const referral = biDefaults.service_page_referral_info;
    const serviceSlug = config.serviceDto!.urlName;
    const isCourse = config.serviceDto!.type === ServiceType.COURSE;

    if (config.serviceDto) {
      const trackingInfo = getTrackingInfoForBookButtonClick({
        service: config.serviceDto,
        businessName: config.businessInfo.name || '',
      });
      if (isUseUtilsInsteadOfWixSDKEnabled) {
        trackAnalytics({
          wixCodeApi: flowAPI.controllerConfig.wixCodeApi,
          eventId: trackingInfo.eventId as any,
          payload: trackingInfo.payload,
        });
      } else {
        wixSdkAdapter.trackAnalytics(trackingInfo);
      }
    }

    const serviceId = config.serviceDto!.id;
    if (isCourse) {
      if (
        flowAPI.experiments.enabled('specs.bookings.navigationFromNewModule')
      ) {
        navigateToBookingsFormPage({
          platformAPIs: flowAPI.controllerConfig.platformAPIs,
          wixCodeApi: flowAPI.controllerConfig.wixCodeApi,
          serviceId,
          serviceSlug,
          isAnywhereSpecEnable: isAnywherePublicDataOverridesEnabled,
          queryParams: { referral, timezone },
        });
      } else {
        wixSdkAdapter.navigateToBookingsFormPage({
          serviceId,
          serviceSlug,
          isAnywhereSpecEnable: isAnywherePublicDataOverridesEnabled,
          queryParams: { referral, timezone },
        });
      }
    } else {
      if (
        flowAPI.experiments.enabled('specs.bookings.navigationFromNewModule')
      ) {
        navigateToBookingsCalendarPage(
          flowAPI.controllerConfig.wixCodeApi,
          flowAPI.controllerConfig.platformAPIs,
          {
            serviceSlugOrBasket: serviceSlug,
            navigationContext: {
              referral,
              location: locationId,
              timezone,
            },
          },
        );
      } else {
        wixSdkAdapter.navigateToBookingsCalendarPage(serviceSlug, {
          referral,
          location: locationId,
          timezone,
        });
      }
    }
  } else {
    if (!featureEnabled) {
      onNavigationFailed({ failReasons: [FailReasons.Premium] });
    } else if (!siteIsEcom) {
      onNavigationFailed({ failReasons: [FailReasons.NonEcomSite] });
    } else {
      const failReasons: FailReasons[] = [];
      if (!isPricingPlanInstalled) {
        failReasons.push(FailReasons.PricingPlanNotInstalled);
      }
      if (!isServiceConnectedToPricingPlan(config.serviceDto)) {
        failReasons.push(FailReasons.NoPlansAssignedToService);
      }
      onNavigationFailed({ failReasons });
    }
  }
};

const isPricingPlanNavigateEnabled = async (
  isPricingPlanInstalled: boolean,
  serviceDto,
) => {
  return (
    !isServiceOfferedAsPricingPlan(serviceDto, isPricingPlanInstalled) ||
    (isServiceConnectedToPricingPlan(serviceDto) && isPricingPlanInstalled)
  );
};
